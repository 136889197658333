.widget-wrapper > div,
#widgetBlock > div.mobile > div {
    @apply h-48 sm:h-64 md:h-72 lg:h-96 rounded-md sm:rounded-lg md:rounded-xl lg:rounded-3xl bg-white dark:dark-surface-300 p-2 sm:p-3 md:p-4 xl:p-5;
}

.widget-wrapper > button {
    @apply p-3 rounded-md flex justify-center items-center;
}

.general-widget-inner {
    @apply flex flex-col gap-2 sm:gap-5 h-full;
}

.general-widget-inner > div:nth-child(1) {
    @apply flex items-center justify-between;
}

.general-widget-inner > div:nth-child(1) > h1 {
    @apply font-bold text-lg sm:text-xl lg:text-2xl xl:text-3xl;
}

.general-widget-inner > div:nth-child(1) > div {
    @apply hidden 2xl:dark:hidden 2xl:block;
}

.general-widget-inner > div:nth-child(2) {
    @apply basis-full overflow-auto;
}

#remaining-days-block {
    @apply bg-white dark:dark-surface-300 p-2 rounded-md;
}

#remaining-days-block > div {
    @apply flex justify-between
}

#remaining-days-block > div > div {
    @apply flex flex-col items-center basis-full gap-1
}

#remaining-days-block > div > div > span:nth-child(1) {
    @apply text-sm
}

#remaining-days-block > div > div > span:nth-child(2) {
    @apply font-bold min-h-6
}

#mode-block {
    @apply flex justify-between;
}

#mode-block > div {
    @apply flex items-center gap-2;
}

#vacation-list-block {
    @apply text-sm overflow-hidden;
}

.min-bubble {
    @apply min-h-20
}

.date-in-range {
    @apply !bg-[#F5CC9C] dark:!bg-orange-400
}

.date-number {
    @apply font-medium
}

@screen lg {
    .date-number {
        @apply text-sm
    }
}
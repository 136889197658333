.big-block {
    flex-basis: 50%;
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.small-block {
    font-size: 16px;
    line-height: 28px;
}

.info-name {
    font-weight: 500;
    font-size: 15px;
    line-height: 20px;
    width: 135px;
    text-overflow: ellipsis;
    text-wrap: nowrap;
    overflow: hidden;
    flex-shrink: 0;
    @apply text-[#6d6d6d] dark:text-white
}

.info-value {
    @apply text-ellipsis overflow-hidden text-black dark:text-slate-300;
    font-weight: 500;
    font-size: 15px;
    line-height: 20px;    
}

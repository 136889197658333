.time-font {
    font-family: 'Inter';
    font-size: 29px;
    text-transform: uppercase;
    line-height: 105%;
}

.time-font:nth-child(2)::before {
    content: ':';
    @apply animate-pulse;
}

.inactive-el {
    opacity: 50%;
}
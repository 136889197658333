.vacation-surface {
    @apply bg-[#E3EBF8] dark:dark-surface-500
}

.base-layer {
    @apply rounded-md lg:rounded-xl xl:rounded-3xl bg-[#E3EBF8] dark:dark-surface-500
}

.main-block-heading {
    @apply text-lg 2xl:text-3xl font-bold
}
.rounded-card {
    @apply rounded p-1 sm:p-3 bg-[#F4F4F6] dark:dark-surface-300 text-sm
}

.vacation-item {
    @apply text-xs sm:text-sm flex flex-col gap-1
}

.action-btn {
    @apply p-1 rounded-md
}
.my-calendar .date-block {
    @apply cursor-pointer transition;
}

.event-popover {
    @apply flex flex-col gap-1;
}

.event-popover > div {
    @apply flex gap-1 items-center
} 

.event-popover > div > span {
    @apply text-nowrap max-w-[300px] sm:max-w-[600px] overflow-hidden text-ellipsis
}

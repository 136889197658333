.tool-icon {
    @apply flex items-center justify-center border-solid border-2 rounded-md;
}

.tool-icon.is-active {
    @apply bg-[#393939] text-white border-[#393939] dark:bg-white dark:text-black dark:border-white;
}

.EmojiPickerReact .epr-btn {
    --epr-emoji-size: 25px;
}

.tiptap p.is-editor-empty:first-child::before {
    color: #adb5bd;
    content: attr(data-placeholder);
    float: left;
    height: 0;
    pointer-events: none;
  }
  
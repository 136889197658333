.action-list {
    @apply flex flex-col gap-2
}

.action-list > div {
    @apply flex justify-between items-center gap-3;
}

.vacation-bubble {
    @apply bg-white dark:dark-surface-300
}

.vacation-bubble .info-row {
    @apply flex gap-2 items-center;
}

.vacation-bubble .info-row .title {
    @apply text-[#8F97B1] dark:dark-text-secondary;
}

.news-tab {
    @apply py-1 px-2 text-sm rounded-xl text-nowrap cursor-pointer;
}

.card-inner {
    @apply h-[85px]
}

.text-el {

}

@screen xl {
    .news-tab {
        @apply px-4 py-2 text-base;
    }

    .card-inner {
        @apply h-[145px]
    }

    .text-el {
        @apply text-nowrap overflow-hidden text-ellipsis max-w-[450px] 
    }
    
}

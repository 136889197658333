.ListItemButton {
    text-wrap: nowrap;
    border-radius: 30px !important;
    padding: 3px 10px !important;
}

.ListItemButton.Mui-selected {
    @apply !bg-white dark:!bg-[#282828]
}

.MuiListItemIcon-root.profile-list-item-icon {
    min-width: 40px !important;
}

.settings-modal {
    @apply rounded-3xl overflow-hidden;
}

.settings-base {
    @apply p-4;
}

.settings-inner {
    @apply bg-white dark:dark-surface-200 overflow-y-auto rounded-3xl h-full;
}

.modal-action-icon {
    @apply text-white flex justify-center items-center p-1 rounded-full bg-black dark:dark-surface-300;
}

.settings-header {
    @apply font-bold text-xl;
}

.horizontal-line {
    @apply hidden
}

.settings-hr {
    @apply dark:border-[#717171]
}

@screen xl {
    .settings-inner {
        @apply h-[50vh] w-[40vw] rounded-none;
    }

    .horizontal-line {
        @apply my-5 w-full block
    }
}

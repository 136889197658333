@tailwind base;
@tailwind components;
@tailwind utilities;
@tailwind variants;

@layer components {
    .avilex-color {
        @apply text-[#ab2121] dark:text-[#A688FA];
    }

    .avilex-surface-color {
        @apply bg-[#ab2121] dark:bg-[#7A5AF5];
    }

    /* скроллы для бокового меню */
    @supports not selector(::-webkit-scrollbar) {
        html .custom-scrollbar {
            scrollbar-width: thin;
            scrollbar-color: rgb(255, 255, 255, 0.64) rgb(0, 0, 0, 0.24);
        }
    }

    html .custom-scrollbar::-webkit-scrollbar {
        width: 5px;
    }

    html .custom-scrollbar::-webkit-scrollbar-track {
        background: rgb(0, 0, 0, 0.24);
        border-radius: 12px;
    }

    html .custom-scrollbar::-webkit-scrollbar-thumb {
        background: rgb(255, 255, 255, 0.64);
        border-radius: 12px;
    }

    .avx-p {
        @apply font-medium;
        @apply text-[15px];
    }

    .avx-page-h {
        @apply text-[28px] font-bold leading-[105%];
    }

    @screen xl {
        .avx-page-h {
            @apply text-[38px];
            @apply font-bold;
            @apply leading-[105%];
        }
    }

    .avx-h1 {
        @apply text-[30px];
    }

    .avx-h2 {
        @apply text-[17px];
    }

    .tip-tap-link {
        text-decoration: underline;
        color: #2b5bb9;
    }

    .tip-tap-link:hover {
        color: #2951a0;
    }

    .external-emoji {
        @apply inline-block align-text-top h-[1.25rem] me-[2px] select-text;
    }

    .external-emoji::selection {
        @apply bg-blue-300;
    }
}

@layer utilities {
    .dark-surface-200 {
        @apply bg-[#282828];
    }

    .dark-surface-300 {
        @apply bg-[#3F3F3F];
    }

    .dark-surface-400 {
        @apply bg-[#575757];
    }

    .dark-surface-500 {
        @apply bg-[#717171];
    }

    .dark-primary-200 {
        @apply bg-[#5e43f3];
    }

    .dark-primary-300 {
        @apply bg-[#7A5AF5];
    }

    .dark-primary-400 {
        @apply bg-[#9171F8];
    }

    .dark-primary-500 {
        @apply bg-[#A688FA];
    }

    .dark-primary-600 {
        @apply bg-[#ba9ffb];
    }

    .dark-primary-text-200 {
        @apply text-[#5e43f3];
    }

    .dark-primary-text-300 {
        @apply text-[#7A5AF5];
    }

    .dark-primary-text-400 {
        @apply text-[#9171F8];
    }

    .dark-primary-text-500 {
        @apply text-[#A688FA];
    }

    .dark-text-primary {
        @apply text-[#ffffff];
    }

    .dark-text-secondary {
        @apply text-[#BFBFBF];
    }
}

body {
    @apply text-black dark:!text-white !bg-[#F4F4F6] dark:!bg-[#282828];
}

.my-modal {
    @apply bg-white dark:dark-surface-200;
}

.tip-tap-link {
    @apply dark:text-[#90caf9];
}

.loading-color {
    @apply bg-slate-300 dark:bg-slate-400 animate-pulse cursor-wait;
}

.text-secondary {
    @apply text-gray-400 dark:text-[#BFBFBF];
}

* {
    padding: 0;
    margin: 0;
    font-family: "Manrope", sans-serif;
    font-display: "auto";
}

::-webkit-scrollbar {
    width: 6px;
    height: 6px;
}

::-webkit-scrollbar-track {
    background: rgb(211, 211, 211, 0.24);
    border-radius: 12px;
}

::-webkit-scrollbar-thumb {
    @apply bg-[#cc3b3b] dark:bg-slate-400;
    border-radius: 12px;
}

@supports not selector(::-webkit-scrollbar) {
    * {
        scrollbar-width: thin;
    }

    html.dark {
        scrollbar-color: rgb(148 163 184) rgb(211, 211, 211, 0.24);
    }

    html {
        scrollbar-color: #cc3b3b rgb(211, 211, 211, 0.24);
    }
}

#managerMonthSelector .selector-arrow {
    @apply cursor-pointer flex justify-center items-center;
}

#managerMonthSelector .selector-arrow .arrow-icon {
    @apply !text-sm lg:!text-base;
}

.selector-popover {
    @apply p-2 rounded bg-gray-100 dark:dark-surface-300 shadow-lg;
}

#managerMonthSelector {
    @apply text-sm lg:text-base
}

.ellipses-text {
    @apply text-ellipsis text-nowrap overflow-hidden;
}

.users-text {
    @apply max-w-[38vw];
}

.company-text {
    @apply max-w-[18vw];
}

.profile-card > div {
    @apply min-h-[35px];
}

.users-list {
    @apply grid grid-cols-1 gap-y-[14px];
}

@screen md {
    .users-list {
        @apply grid-cols-2 gap-3;
    }

    .users-text {
        @apply max-w-[18vw];
    }

    .company-text {
        @apply max-w-[9vw];
    }
}

@screen xl {
    .profile-card > div {
        min-height: 40px;
    }
}

@screen 2xl {
    .users-text {
        @apply max-w-[10.5vw];
    }
    .company-text {
        @apply text-wrap max-w-none;
    }
    .users-list {
        @apply gap-x-[38px] grid-cols-3;
    }
}

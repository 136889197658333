#monthSelector.selector {
    @apply flex justify-between gap-5 items-center bg-blue-300/80 dark:bg-slate-400/80 text-2xl font-bold rounded-xl w-full sm:w-[300px]
}

#monthSelector .selector-arrow {
    @apply flex justify-center items-center cursor-pointer
}

#yearSelector.selector {
    @apply flex justify-between gap-5 items-center bg-blue-300/80 dark:bg-slate-400/80 text-2xl font-bold rounded-xl w-full sm:w-[300px]
}

#yearSelector .selector-arrow {
    @apply flex justify-center items-center cursor-pointer
}
.week-day {
    @apply text-xs md:text-sm lg:text-base min-h-7 md:min-h-9 flex justify-center items-center;
}

.cell {
    @apply h-12 md:h-16;
}

.row-padding {
    @apply pb-3;
}

.month-selector-block {
    @apply min-h-12
}

.lower-padding {
    @apply min-h-2
}
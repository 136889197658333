.general-users-block {
    @apply grid grid-cols-1 md:grid-cols-2 lg:grid-cols-1 gap-5 overflow-y-auto;
}

.general-users-block > div,
.general-users-block > a {
    @apply flex items-center gap-3;
}

.general-user-image {
    @apply w-[42px] text-[42px] lg:w-[50px] lg:text-[50px] xl:w-[57px] xl:text-[57px] shrink-0;
}

.general-users-block.loading > div > div:nth-child(1) {
    @apply size-[42px] lg:size-[50px] xl:size-[57px] rounded-full shrink-0;
}

.general-users-block.loading > div > div:nth-child(2) {
    @apply basis-full h-[48px] rounded-lg me-2;
}

.no-results-block {
    @apply lg:text-lg font-semibold h-full flex items-center justify-center;
}
